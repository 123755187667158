import React, { useRef } from 'react'
import { SERVER_HOST } from '../components/service/ApiService'
import { PDFViewer } from "@progress/kendo-react-pdf-viewer";
import { useFibaMsgBox } from '../components';

const pdfRegex = /^.*\.pdf$/;
const DetailComponent = ({ detail }) => {
    const printableRef = useRef();
    const { confirm } = useFibaMsgBox()
    const tools = [
        "pager",
        "spacer",
        "zoomInOut",
        "zoom",
        "selection",
        "spacer",
        "search",
        "print",
    ];
    let filetype = 'jpg';
    if (detail.filename) {
        if (pdfRegex.test(detail.filename)) {
            filetype = 'pdf';
        }
    }

    const printClick = async () => {
        const isconfirm = await confirm('Мэдээллийг хэвлэхдээ итгэлтэй байна уу?');
        if (isconfirm) {
            window.print();
        }
    }

    return (
        <div>
            <div className='mx-auto max-w-7xl px-7 bg-white space-y-3 py-3'>
                {/* <div className='flex justify-end'>
                    <button
                        type="button"
                        className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={printClick}
                    >
                        {'Хэвлэх'}
                    </button>
                </div> */}
                {detail.type === '01' && <>
                    <div className='text-center text-3xl font-medium'>
                        {detail.name}
                    </div>
                    <div className='border my-3 border-primary-500 w-16 m-auto'></div>
                    {/* <div className='text-sm'>
                        {detail.description}
                    </div> */}
                    <div>
                        {(detail.photo1 && detail.photo1 !== 'null') && <img
                            className='mx-auto md:w-1/2'
                            src={SERVER_HOST + "/api/v1/image?type=GPA&id=" + detail.photo1} alt={detail.name} />}
                    </div>
                    <div className='text-sm editor' ref={printableRef}>
                        <div style={{ maxWidth: '100vw' }} dangerouslySetInnerHTML={{ __html: detail.information }} />
                    </div>
                    {detail.photo2 && <div>
                        <img
                            className='mx-auto md:w-1/2'
                            src={SERVER_HOST + "/api/v1/image?type=GPA&id=" + detail.photo2} alt={''} />
                    </div>}
                </>
                }
                {detail.type === '02' &&
                    <iframe
                        src={SERVER_HOST + '/api/v1/script?id=' + detail.id}
                        sandbox="allow-scripts allow-same-origin"
                        width="100%"
                        height="100%"
                        title={detail.name}
                    >
                    </iframe>
                }
                {detail.type === '03' &&
                    <>
                        <div className='text-center text-3xl font-medium'>
                            {detail.name}
                        </div>
                        <div className='border my-3 border-primary-500 w-16 m-auto'></div>
                        {filetype === 'pdf' && <PDFViewer
                            data={detail.information}
                            style={{
                                height: '80vh',
                            }}
                            tools={tools}
                        />}

                        {filetype !== 'pdf' &&
                            <img src={`data:image/png;base64,${detail.information}`} alt='' className='mx-auto' />
                        }
                    </>
                }
            </div>
        </div>
    )
}

export default DetailComponent