import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <footer className="bg-white fixed bottom-0 min-w-[100vw]" aria-labelledby="footer-heading">
            <Link to="/"
                title='Нүүр хуудас'
                className='text-primary-500 fixed bottom-4 right-4 hover:cursor-pointer text-center
                        border-primary-500 rounded-full border-[2px] p-4 bg-white font-medium'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-12 h-12 m-auto">
                    <path d="M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z" />
                    <path d="m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z" />
                </svg>
                Нүүр <br />хуудас
            </Link>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="border-t border-gray-900/10 py-4 text-center">
                    <p className="text-xs leading-5 text-gray-500 font-medium">
                        &copy; {(new Date()).getFullYear()} АНХНЫ-ОЧ Өрхийн Эрүүл Мэндийн Төв.
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer